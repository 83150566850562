import {getInstagramImage, getInstagramMedia} from "../../api/viz-api/viz-api";
import {useState} from "react";
import {InstagramImage, InstagramMedium} from "../../api/viz-api/model";
import { isNil } from "lodash";

export const useInstagramImages = (size: number) => {

    const [fetchResult, setFetchResult] = useState<InstagramImage[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadData = async () => {
        setIsLoading(true);
        const result: InstagramMedium[] = await getInstagramMedia(size);
        if (isNil(result)) {
            setFetchResult([]);
        } else {
            const pictures: InstagramImage[] = await Promise.all(result.map(({id}) => getInstagramImage(id)));
            setIsLoading(false);
            const [one, two, three, four, five, six] = pictures.filter(value => isNil(value.media_url) ? '' : !value.media_url.startsWith("https://video"));
            setFetchResult([one, two, three, four, five, six]);
        }
    };

    return {
        fetch: loadData,
        fetchResult,
        isLoading
    }
};