import {BannerImage, parseDescription, Zoom} from "./use-google-folder";
import {useEffect, useState} from "react";
import {isNil} from 'lodash';
import {Image} from "../../api/viz-api/model";

interface Slide {
    picture: string;
    title?: string;
    supertitle?: string;
    subtitle?: string;
    color?: string;
    fit?: string;
    position?: string;
    zoom?: Zoom;
}

const bannerImages: Image[] = [
    {
        id: '',
        kind: '',
        description: '{"title" : "Zurich", "superTitle":"Aan de Limmat", "subTitle":"", "zoom":{"start":"100", "end":"110", "offsetStart":"0 0", "offsetEnd":"0 0"}}',
        name: 'zurich',
        webContentLink: '/images/banner/zurich.jpg'
    },
    {
        id: '',
        kind: '',
        description: '{"title" : "De Pilatus beklimming", "subTitle" : "", "fit":"cover", "zoom":{"start":"100", "end":"110", "offsetStart":"0 0", "offsetEnd":"0 0"}}',
        name: 'pilatus',
        webContentLink: '/images/banner/pilatus-collage.jpg'
    },
    {
        id: '',
        kind: '',
        description: '{"title" : "Ski pret", "superTitle":"", "subTitle":"ViZFit", "zoom":{"start":"100", "end":"110", "offsetStart":"0 0", "offsetEnd":"0 0"}}',
        name: 'laax',
        webContentLink: '/images/banner/laax-flims.jpg'
    },
    {
        id: '',
        kind: '',
        description: '{"title" : "Ski pret", "superTitle":"", "subTitle":"ViZFit", "zoom":{"start":"100", "end":"110", "offsetStart":"0 0", "offsetEnd":"0 0"}, "position":"top"}',
        name: 'engelberg',
        webContentLink: '/images/banner/engelberg.jpg'
    },
    {
        id: '',
        kind: '',
        description: '{"title" : "Fietstochtje", "superTitle":"", "subTitle":"ViZFit", "zoom":{"start":"100", "end":"110", "offsetStart":"0 0", "offsetEnd":"0 0"}}',
        name: 'cycling',
        webContentLink: '/images/banner/cycling.jpg'
    },
    {
        id: '',
        kind: '',
        description: '{"title" : "Clubhouse tijdens EK en WK", "subTitle" : "Steeds weer Full House"}',
        name: 'belgian house',
        webContentLink: '/images/banner/belgian-house.jpeg'
    }
]

export const useBanner = () => {
    const [images, setImages] = useState<Slide[]>([]);

    useEffect(() => {
        const loadImagesFromFolder = async () => {
            if (!isNil(bannerImages)) {
                const loadedImages: BannerImage[] = bannerImages.map(value => ({
                    description: parseDescription(value.description),
                    name: value.name,
                    source: value.webContentLink
                }));
                setImages(loadedImages.map(image => (
                    {
                        picture: image.source,
                        title: image.description.title,
                        subtitle: image.description.subTitle,
                        supertitle: image.description.superTitle,
                        color: image.description.color,
                        fit: image.description.fit,
                        position: image.description.position,
                        zoom: image.description.zoom
                    }
                )))
            }
        };
        loadImagesFromFolder();
    }, [bannerImages]);

    return {
        images
    }
};