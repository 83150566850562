import React, {PureComponent} from 'react';

import omit from 'lodash/omit';
import classnames from 'classnames';

import Image from '../Image';

import {imageDefaultProps,} from '../../common';
import noop from '../../utils/noop';


const defaultProps = {
    ...imageDefaultProps,
    photo: null,
    onPress: noop,
    onTouchStart: noop,
    onTouchMove: noop,
    onTouchEnd: noop,
};

class Photo extends PureComponent {
    constructor(props) {
        super(props);
        this.onPress = this.onPress.bind(this);
    }

    onPress() {
        const {onPress} = this.props;
        onPress();
    }

    renderPhoto() {
        const {
            photo,
            onTouchStart,
            onTouchMove,
            onTouchEnd,
            ...rest
        } = this.props;

        if (!photo) {
            return null;
        }

        const {
            onLoad,
            onError,
            style,
        } = omit(rest, [
            'onPress',
        ]);

        return (
            <button
                type="button"
                onClick={this.onPress}
                className="photo-button"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <Image
                    alt={photo.caption || ''}
                    className="photo"
                    src={photo.photo}
                    onLoad={onLoad}
                    onError={onError}
                    style={style}
                />
            </button>
        );
    }

    render() {
        const className = classnames(
            'gallery-media-photo',
            'gallery-media-photo--block',
            'gallery-media-cover',
        );

        const photoRendered = this.renderPhoto();

        return (
            <ul className="gallery-images--ul">
                <li className={className}>
                    {photoRendered}
                </li>
            </ul>
        );
    }
}

Photo.defaultProps = defaultProps;

export default Photo;
