import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import moment from "moment-with-locales-es6";
import {FacebookEvent, SaveTheDateEvent} from "../api/viz-api/model";
import {saveTheDateEvents} from "../utils";
import Slider, {Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {isNil} from 'lodash';
import {useScreen} from "../hooks/use-screen";

interface SaveTheDateProps {
    facebookEvents: FacebookEvent[];
}

function CustomArrow(props:any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#f1f2f4" }}
            onClick={onClick}
        />
    );
}

const getSettings = (events: FacebookEvent[], isMobileDevice: boolean, isLargeScreen: boolean): Settings => ({
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "cubic-bezier(.42,.97,.52,1.49)",
    slidesToScroll: isLargeScreen ? 3 : 3,
    pauseOnHover: true,
    infinite: false,
    initialSlide: 0,
    slidesToShow: isLargeScreen ? 5 : 3,
    vertical: isLargeScreen || isMobileDevice,
    verticalSwiping: isLargeScreen || isMobileDevice,
    dots: true,
    dotsClass: "mydots",
    arrows: false,
    prevArrow: <CustomArrow/>,
    nextArrow: <CustomArrow/>
});

interface CategoryColor {
    color: string;
    backgroundColor: string;
}

const ALL_CATEGORY_LABEL = 'Alle';

export const SaveTheDate: FC<SaveTheDateProps> = ({facebookEvents}) => {
    const {isMobileDevice, isLargeScreen} = useScreen();

    const [events, setEvents] = useState<SaveTheDateEvent[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const categories = useMemo(() => events.filter(({category}) => category !== '').map(({category}) => category), [events]);

    useEffect(() => {
        if (!isNil(selectedCategory) && selectedCategory !== '') {
            setEvents(prevState => prevState.filter(({category}) => category === selectedCategory))
        } else {
            setEvents(saveTheDateEvents(facebookEvents));
        }
    }, [selectedCategory, setEvents]);

    const renderCategory = useCallback((category: string, handler: (cat: string) => void) => {
        const isCurrentCategory = (category === selectedCategory) || ((isNil(selectedCategory) || selectedCategory === '') && category === ALL_CATEGORY_LABEL);

        return <button className="mybutton" key={category} onClick={() => handler(category)}
                       style={{
                           backgroundColor: isCurrentCategory ? '#A9A9A9' : 'white',
                           color: isCurrentCategory ? 'white' : 'black',
                           border: '1px solid black',
                           padding: '.3em 1em',
                           marginRight: '1em',
                           fontWeight: 'bold'
                       }}>{category}</button>;
    }, [selectedCategory]);

    return <>
        {categories.length > 0 ?
            <>
                <p>{renderCategory(ALL_CATEGORY_LABEL, () => setSelectedCategory(''))}</p>
                <p>{categories.map(value => renderCategory(value, setSelectedCategory))}</p>
            </>
            : <></>}

        <div className="save-the-date-container">
            <Slider {...getSettings(events, isMobileDevice, isLargeScreen)}>
                {events.map(({name, start_time, description}) => {
                    const eventDate = moment(start_time);
                    return <div key={name}>
                        <article className="save-the-date-item">
                            <div className="save-the-date-when">
                                <div className="save-the-date-day">{eventDate.locale('nl').format('D')}</div>
                                <div
                                    className="save-the-date-month">{`${eventDate.locale('nl').format('MMM')}`}
                                    '{eventDate.locale('nl').format('YY')}
                                </div>
                            </div>
                            <div className="save-the-date-content">
                                <div className="save-the-date-title">{name}</div>
                                <span className="save-the-date-time"><i
                                    className="fa fa-clock-o mr-5 text-theme-colored"/>{eventDate.locale('nl').format('kk:mm')}</span>
                            </div>
                        </article>
                    </div>;
                })}
            </Slider>
        </div>
    </>;
};