import React, {FC} from "react";

export const About: FC = () =>
    <div className="about-container">
        <h1><div className="quote about-title">Beleef bij ons de Vlaamse gezelligheid in Zurich</div></h1>
        <div className='about-text'>
            <h1>De Vlamingen in Zurich (beter gekend als de <i>ViZkes</i>) is een vrijblijvende groep door en voor
                Vlamingen woonachtig in de buurt van Zurich. Wij bieden een platform om ervaringen uit te
                wisselen, banden met mede-ViZkes te smeden en onvergetelijke momenten te beleven in een
                gemoedelijke Vlaamse sfeer.
            </h1>
        </div>
    </div>;