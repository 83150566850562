import React, {FC, useCallback, useMemo, useRef, useState} from "react";
import {FacebookEvent} from "../api/viz-api/model";
import {EventCard} from "../components/event-card";
import {getDisplayedEvents, randomPicture} from "../utils";
import {isNil} from 'lodash';
import {useScreen} from "../hooks/use-screen";
import Slider, {Settings} from "react-slick";
import ReactBnbGallery from "../components/gallery";
import Linkify from "react-linkify";
import moment from "moment-with-locales-es6";

const parse = require('html-react-parser');

interface EventsProps {
    facebookEvents: FacebookEvent[];
    notLoaded: boolean
}

export const Events: FC<EventsProps> = ({facebookEvents, notLoaded}) => {
    const {isTablet, isLargeScreen} = useScreen();
    const [events] = useState<FacebookEvent[]>(getDisplayedEvents(facebookEvents));

    const maxEvents = isTablet ? 9 : events.length;

    const [selectedEvent, setSelectedEvent] = useState<FacebookEvent>();

    const handleSelect = (event: FacebookEvent) => {
        setSelectedEvent(event);
    };

    const eventsSlider = useRef<any>();

    const next = () => {
        eventsSlider.current.slickNext();
    };

    const previous = () => {
        eventsSlider.current.slickPrev();
    };

    const [currentSlide, setCurrentSlide] = useState(0);

    const slidesToShow = isTablet ? 1 : isLargeScreen ? 3 : 2;

    const handleClose = () => {
        setSelectedEvent(undefined);
    };

    const config: Settings = {
        autoplay: false,
        slidesToScroll: slidesToShow,
        afterChange: setCurrentSlide,
        slidesToShow: slidesToShow,
        infinite: false, swipe: true, edgeFriction: 1,
        dots: true,
        dotsClass: "mydots",
        arrows: false,
        cssEase: "cubic-bezier(.42,.97,.52,1.1)"
    };

    const showArrow = isLargeScreen;

    const renderDescription = (text: string) => <span key={text} className='subcaption-modal'>{text}</span>;

    const parseDescription = useCallback((description: string) => {
        const parsed: string | string[] = parse(description.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />'));
        const content = typeof parsed == 'string' ?
            renderDescription(parsed) : parsed.map(value => renderDescription(value));
        return <Linkify componentDecorator={decoratedHref => <a
            style={{textDecoration: "underline"}} href={decoratedHref}
            target='_blank'>{decoratedHref}</a>}>{content}</Linkify>
    }, []);

    const album = useMemo(() => events.map(event => {
        const pastEvent: boolean = moment(event.start_time).isBefore(moment());
        const parsed: string | string[] = parse(event.description.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />'));
        return ({
            id: event.id,
            photo: !isNil(event.cover) ? event.cover.source : randomPicture(300, 300),
            caption: <span
                className='caption-modal'>{event.name} {event.is_canceled ? '(Geannuleerd)' : (pastEvent ? ' (Afgelopen)' : '')}</span>,
            subcaption: parseDescription(event.description)
        });
    }), [events]);

    const findIndex = useCallback(() => album.map((value, index) => value.id === selectedEvent!.id ? index : -1).filter(value => value !== -1)[0], [selectedEvent, album]);

    return notLoaded ?
        <p style={{color: 'red'}}>Er kunnen momenteel geen evenementen geladen worden omwille van gewijzigde voorwaarden
            met betrekking tot Facebook. Dit wordt de komende weken in orde gebracht. Even geduld aub :)</p> :
        events.length > 0 ?
            <>
                <div className="events-slider">
                    <Slider ref={eventsSlider} {...config}
                    >
                        {
                            events.slice(0, maxEvents).map((event) => <EventCard key={event.id}
                                                                                 event={event}
                                                                                 onSelect={handleSelect}/>)
                        }
                    </Slider>
                    {showArrow && (currentSlide !== maxEvents - slidesToShow) && events.length > 1 ?
                        <i onClick={next} className="arrow right"/> : <></>}
                    {showArrow && currentSlide !== 0 && events.length > 1 ?
                        <i onClick={previous} className="arrow left"/> : <></>}

                </div>
                {selectedEvent && <ReactBnbGallery onClose={handleClose} show={!isNil(selectedEvent)} photos={album}
                                                   activePhotoIndex={findIndex()}/>}
            </> : <>Er zijn momenteel geen evenementen. Kom later nog eens terug.</>;
};