import moment from "moment";
import {FacebookEvent, SaveTheDateEvent} from "./api/viz-api/model";
import {isNil} from 'lodash';

export const randomPicture = (width: number, height: number) => `https://picsum.photos/${width}/${height}?${Math.random()}`;

const isNewEvent = (event: FacebookEvent) => !moment(event.start_time).isBefore(moment());
const isPastEvent = (event: FacebookEvent) => moment(event.start_time).isBefore(moment());
const sortByTimeAsc = (a: FacebookEvent, b: FacebookEvent) => moment(a.start_time).isBefore(moment(b.start_time)) ? -1 : 1;
const sortByTimeDesc = (a: FacebookEvent, b: FacebookEvent) => moment(a.start_time).isAfter(moment(b.start_time)) ? -1 : 1;

export const getDisplayedEvents = (events: FacebookEvent[]): FacebookEvent[] => {
    const now = moment();
    return (events || []).filter(isNewEvent).sort(sortByTimeAsc)
        .concat((events || []).filter(isPastEvent).sort(sortByTimeDesc))
        .filter(({name}) => !name.toLowerCase().includes('save the date'))
        .map(event => ({...event, complete: event.description.indexOf("!VOLZET!") !== -1}))
        .map(event => ({...event, description: event.description.replace("!VOLZET!", "")}));
};

export const saveTheDateEvents = (events: FacebookEvent[]): SaveTheDateEvent[] =>
    isNil(events) ? [] : events
        .filter(({name}) => name.toLowerCase().includes('save the date'))
        .map(value => ({...value, name: value.name.substr('save the date:'.length)}))
        .filter(({
                     start_time,
                     end_time
                 }) => !isNil(end_time) ?moment(start_time).isAfter(moment()) || moment().isBetween(moment(start_time), moment(end_time)) : moment(start_time).isAfter(moment()))
        .sort(({start_time: a}, {start_time: b}) => moment(a).isBefore(moment(b)) ? -1 : 1)
        .map(value => ({...value, category: getCategorie(value)}))
        .map(value => value.category !== '' ? ({
            ...value,
            name: value.name.substr(value.name.indexOf(']:') + 2)
        }) : value);


export const getCategorie = ({name}: FacebookEvent): string => {
    var matches = name.match(/\[(.*?)\]/);
    return matches ? matches[1] : '';
};
