import React, {FC, useState} from "react";
import {links} from "../data";
import {ScreenState} from "../hooks/use-screen";
import {useWindowDimensions} from "../hooks/use-window-dimensions";

interface FooterProps {
    screen: ScreenState;
}

export const Footer: FC<FooterProps> = ({screen: {isMobileDevice}}) => {

    const {innerWidth, innerHeight} = useWindowDimensions();
    const {footer: {bass, viw, ambasade, ubu, mzs}, facebook: {group}, whatsapp, instagram} = links;
    const [subscribed, setSubscribed] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const handleSubscribed = () => {
        setSubscribed(true);
        setMessage('Bedankt voor de inschrijving.');
        setTimeout(() => setMessage(''), 5000)
    };

    return (
        <>
            <footer id="footer" className="footer">
                <div className="widget dark">
                    <h5 className="widget-title">Handige Links</h5>
                    <ul className="list angle-double-right list-border">
                        <li>
                            <h6><a href={ambasade} target="_blank" style={{color: "black"}}>Belgische
                                ambassade in Zwitserland</a></h6>
                        </li>
                        <li>
                            <h6><a href={viw} target="_blank" style={{color: "black"}}>Vlamingen in de
                                Wereld</a></h6>
                        </li>
                        <li>
                            <h6><a href={bass} target="_blank" style={{color: "black"}}>Studentenvereniging
                                BASS</a></h6>
                        </li>
                        <li>
                            <h6><a href={ubu} target="_blank" style={{color: "black"}}>Belgische Unie</a></h6>
                        </li>
                        <li>
                            <h6><a href={mzs} target="_blank" style={{color: "black"}}>Magische Zwitserse Steden</a>
                            </h6>
                        </li>
                    </ul>
                </div>
                <div className="widget dark">
                    <h5 className="widget-title">Contacteer Ons</h5>
                    <ul className="list-border">
                        <li><h6><a href="mailto:vlamingeninzueri@gmail.com" style={{color: "black"}}>Via
                            email</a></h6>
                        </li>
                    </ul>
                </div>
            </footer>
            <div className="myfooter" style={{color: "white"}}>
                <div className="container">
                    <div className="copyright">
                        <span>
                            Werkt iets niets? <a style={{textDecoration: 'underline'}} href="mailto:
                            krisbude@gmail.com">Laat het ons weten</a>
                        </span>
                        <p>
                            {`(${innerWidth}x${innerHeight})`}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};