import React, {FC, useEffect, useState} from "react";
import {useGoogleFolder} from "../hooks/google/use-google-folder";
import {isNil} from "lodash";
import {Album} from "./album";
import {getImages as loadImages} from "../api/viz-api/viz-api";
import {useScreen} from "../hooks/use-screen";

interface GoogleFolderProps {
    id: string;
    pageSize?: number;
}

interface Thumbnail {
    source?: string;
    name: string;
    id: string;
}

export const GoogleFolder: FC<GoogleFolderProps> = ({id, pageSize}) => {
    const {fetch: getImages, fetchResult: allImages, getNextPage: getNextImages, hasNextPage: hasMoreImages, isLoading: loadingImages} = useGoogleFolder(id, pageSize);
    const [selectedFolder, setSelectedFolder] = useState<string>();
    const [thumbnails, setThumbnails] = useState<Thumbnail[]>([]);


    useEffect(() => {
        getImages();
    }, []);

    useEffect(() => {
        const loadThumbnails = async () => {
            const thumbmails: Thumbnail[] = await Promise.all(allImages.filter(value => value.id !== '1PShXNSFMdurQlYLYB8fVEF-lba3OuROA').map(async ({id, name}) => {
                const page = await loadImages(id, 2);
                return {source: page.images.length > 1 ? page.images[1].webContentLink : undefined, name, id}
            }));
            setThumbnails(thumbmails.filter(value => !isNil(value.source)));
        };
        loadThumbnails();
    }, [allImages]);

    const {isMobileDevice, isTablet} = useScreen();

    const getColumns = () => {
        if (isMobileDevice) {
            return 1;
        } else if (isTablet) {
            return 2;
        } else return 3;
    };

    const groupThumbnails = (thumbnails: Thumbnail[]) =>
        <div style={{
            padding: "1em",
            margin: "4em",
            display: "grid",
            gridTemplateColumns: `repeat(${getColumns()}, auto)`,
            justifyContent: "center",
            gap: "4em"
        }}>
            {thumbnails.map(({source, id, name}, index) =>
                <div key={index} style={{position: "relative"}} className="album">
                    {!isNil(source) ?
                        <>
                            <img alt={name} onClick={() => setSelectedFolder(id)}
                                 style={{
                                     boxShadow: "10px 0 20px",
                                     fontSize: ".6rem",
                                     cursor: "pointer",
                                     objectFit: "cover",
                                     width: "400px",
                                     height: "300px",
                                     border: "2px solid black"
                                 }}
                                 src={source}/>
                            <div style={{
                                backgroundColor: "rgba(0, 0, 0, .5)",
                                fontWeight: "bold",
                                position: "absolute",
                                bottom: 0,
                                textAlign: "center",
                                color: "white",
                                width: "100%",
                                fontSize: "1rem"
                            }}>{name.substr(name.indexOf("-") + 1)}</div>
                        </> : <>Picture could not be loaded</>}
                    {!isNil(selectedFolder) && selectedFolder === id ?
                        <div style={{fontWeight: "bold"}}><Album
                            folderId={selectedFolder}/></div> : null}
                </div>)}
        </div>;

    return loadingImages ?
        <div style={{textAlign: "center", fontSize: '1.2rem', marginBottom: '10em'}}>Bezig met
            laden...</div> : groupThumbnails(thumbnails);
};