import React, {PureComponent} from 'react';

import classnames from 'classnames';

import Image from '../Image';

import noop from '../../utils/noop';

import {THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH,} from '../../constants';

const thumbnailStyle = {
    width: THUMBNAIL_WIDTH,
    height: THUMBNAIL_HEIGHT,
};

const defaultProps = {
    active: false,
    photo: null,
    onPress: noop,
    number: 0,
};

class Thumbnail extends PureComponent {
    render() {
        const {
            active,
            photo,
            onPress,
            number,
        } = this.props;

        const className = classnames(
            'thumbnail-button',
            active && 'active',
        );

        return (
            <button
                type="button"
                aria-label={photo.caption}
                className={className}
                data-photo-index={number}
                onClick={onPress}
                disabled={false}
            >
                <Image
                    alt={photo.caption}
                    src={photo.thumbnail || photo.photo}
                    className="thumbnail"
                    style={thumbnailStyle}
                />
            </button>
        );
    }
}

Thumbnail.defaultProps = defaultProps;

export default Thumbnail;
