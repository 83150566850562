exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./base.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./menu.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./instagram.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./logo.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./subgroups-section.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./events-section.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./history.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./board-section.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./introduction-section.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./about-section.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./modal.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./event-card.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./footer.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./banner-section.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./section.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./follow.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./newsletters.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./album.css"), "");

// module
exports.push([module.id, ".section-container {\n    overflow-x: hidden;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: auto;\n    --section-gap: 5em;\n    grid-gap: var(--section-gap);\n}\n\n.hidden {\n    display: none;\n}\n\n.container {\n    margin-right: auto;\n    margin-left: auto;\n    width: 80vw;\n}\n\n.quote::before {\n    content: '\\201C';\n    font-family: 'Alex Brush', cursive;\n}\n\n.quote::after {\n    content: '\\201D';\n    font-family: 'Alex Brush', cursive;\n}\n\n.loading-banner {\n    height: 50vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n@media (max-width: 1200px) {\n    .loading-banner {\n        height: 20vh;\n    }\n\n    .container {\n        width: 95vw;\n    }\n\n\n    /*.section-container {\n     overflow-y: scroll;\n     height:100vh;\n     scroll-snap-type: y mandatory;\n    }\n\n    section {\n     height: 100vh;\n     overflow: hidden;\n     scroll-snap-align: start;\n    }*/\n}\n\n@media (max-width: 480px) {\n    .loading-banner {\n        height: 10vh;\n    }\n\n    .section-container {\n        --section-gap: 10em;\n    }\n}", ""]);

// exports
