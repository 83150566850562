import React, {FC, useEffect, useState} from "react";
import {useGoogleFolder} from "../hooks/google/use-google-folder";
import {isEmpty, isNil} from 'lodash';
import ReactBnbGallery from "./gallery";

interface AlbumProps {
    folderId: string;
}

export const Album: FC<AlbumProps> = ({folderId}) => {

    const [photos, setPhotos] = useState<any[]>([]);
    const [showAlbum, setShowAlbum] = useState<boolean>(true);
    const {fetch: getImages, fetchResult: allImages, getNextPage: getNextImages, hasNextPage: hasMoreImages, isLoading: loadingImages} = useGoogleFolder(folderId);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(0);

    useEffect(() => {
        getImages()
    }, []);

    useEffect(() => {
        const doLoadMedia = async () => {
            if (!isNil(allImages)) {
                const media = await Promise.all(allImages.map(value => value.webContentLink));
                setPhotos(media.map(value => ({photo: value})))
            }
        };
        doLoadMedia();
    }, [allImages]);

    const nextButtonPressed = () => {
        if (hasMoreImages()) {
            //getNextImages()
        }
    };

    return (
        isEmpty(photos) ? <></>:
            <ReactBnbGallery nextButtonPressed={nextButtonPressed} photos={photos}
                             onClose={() => setShowAlbum(!showAlbum)} show={showAlbum} showThumbnails={true}
                             wrap={true}/>
    )
};