import React, {FC, useState} from 'react';
import classNames from "classnames";
import {Logo} from "./logo";
import Linkify from "react-linkify";
import {FacebookEvent} from "../api/viz-api/model";
import moment from "moment-with-locales-es6";
import {isNil} from 'lodash';
import {links} from "../data";
import '../style/event-card.css';
import {useScreen} from "../hooks/use-screen";

var parse = require('html-react-parser');


interface EventCardProps {
    event: FacebookEvent;
    onSelect: (event: FacebookEvent) => void;
}

export const EventCard: FC<EventCardProps> = ({event, onSelect}) => {
    const {isLargeScreen} = useScreen();
    const {start_time, description, cover, name, id, attending_count} = event;
    const [overflowed, setOverflowed] = useState<{ [key: string]: boolean }>();
    const eventDate = moment(start_time);
    const pastEvent = eventDate.isBefore(moment());
    const cancelled = event.is_canceled;
    const complete = event.complete;
    const stamp = classNames('past-event', 'stamp', {'is-past': pastEvent}, {'is-cancelled': cancelled}, {'is-complete': complete}, {hidden: !pastEvent && !complete});
    const opacity = pastEvent ? '0.3' : '1';
    return (
        <article className="event-card">
            <div className={classNames('event-header')}>
                {!isNil(cover) ?
                    <img alt="" src={cover.source} className={classNames('event-image', {faded: pastEvent})}/> :
                    <Logo text={name} logoClassName='event-logo'
                          containerClassName={classNames('event-logo-container', 'event-image', {faded: pastEvent})}/>}
                <div className={stamp}>{cancelled ? 'geannuleerd' : complete ? 'volzet' : 'afgelopen'}</div>
            </div>
            <div className={classNames('event-body', {faded: pastEvent})}>
                <div className="event-body-header">
                    <div className="event-date">
                        <div className="event-day">{eventDate.locale('nl').format('D')}</div>
                        <div
                            className="event-month">{`${eventDate.locale('nl').format('MMM')} '${eventDate.locale('nl').format('YY')}`}</div>
                    </div>
                    <div className="event-body-header-content">
                        <h4 className="event-title">
                            {pastEvent ? name : <a href={links.facebook.events}
                                                   target="_blank">{name}</a>}
                        </h4>
                        <span
                            className="event-info"><i
                            className="fa fa-user-times mr-5 text-theme-colored"/> {attending_count} deelnemers</span>
                        {pastEvent ? null :
                            <a href={links.facebook.events}
                               target="_blank"
                               className="event-info font-weight-600"> | <i
                                className="fa fa-facebook mr-5 text-theme-colored"/>Schrijf
                                je in</a>}
                    </div>
                </div>
                <div className="event-description">
                    <p><Linkify>{parse(description.replace(/\r\n/g, '<br />').replace(/[\r\n]/g, '<br />'))}</Linkify>
                    </p>
                </div>
            </div>
            <button className="event-read-more"
                    onClick={() => onSelect(event)}>Lees meer
            </button>
        </article>
    );
};

