import React, {FC} from "react";
import {RouteComponentProps} from "react-router";
import {Layout} from "../layout/Layout";
import {Title} from "../components/title";

export const HistoryPage: FC<RouteComponentProps> = ({...props}) =>
    <Layout {...props}>
        <section>
            <div className="container">
                <Title title="Onze Geschiedenis" subTitle=""/>
                <div style={{textAlign: "justify", left: "auto", right: "auto", margin: '2em 0'}}>
                    <h2 className="quote"><em>Het Begin</em></h2>
                    <div className='history-text'>
                        <p>In <b>2010</b> verhuisden zowel <b>Insun Adriaansen</b> als <b>Barbara Truyers</b> beiden
                            vanuit
                            België naar het Zwitserse Zürich.
                            Via gemeenschappelijke vrienden uit België kwamen ze hier als “verse Belgische expats” met
                            elkaar in contact. Al snel waren ze het erover eens dat Zürich een enorm groot aanbod had
                            aan
                            clubs voor expats of
                            buitenlanders van een bepaalde afkomst (bv. de Hollandse Borrels, les Jeudis Francophones,
                            The
                            Aussi Saturdays enzovoort).
                            Ondanks het feit dat al deze clubs leuke bijeenkomsten organiseerden, misten ze toch het
                            contact
                            met andere Belgen in
                            hun dagelijkse leven in Zwitserland… Zo stelde zich de vraag: “Zou het geen goed idee zijn
                            om
                            een eigen Belgische (Vlaamse) club op te richten!?!”.
                        </p>
                        <br/>
                        <p>
                            En zo geschiedde… Insun nam de koe bij de horens en zette de eerste stap bij wijze van het
                            oprichten van een website. Deze ging live op 1 januari 2011. De oprichting van deze website
                            staat dus eigenlijk
                            symbool met het eigenlijke begin van de “Vlamingen in Zürich”.
                            Uiteraard was iedereen welkom om zich aan te sluiten en ze maakten dan ook geen onderscheid
                            in
                            leeftijd en nationaliteit. Een spannend moment was de allereerste ViZkes afterwork drink:
                            “Hoeveel mensen
                            zouden er komen?
                            Gaat er überhaupt iemand komen of wordt het een gezellige drink met zijn tweeën?”
                            Uiteindelijk
                            waren er op deze
                            eerste drink toch al een zestal ViZkes afgekomen! Na een drietal maanden telden de ViZkes
                            zelfs
                            al 24 leden en nog een tiental sympathisanten.
                        </p>
                        <br/>
                        <p>
                            Mede door de onuitputtelijke inzet van Insun en Barbara (aka de oprichtsters van de ViZkes),
                            groeiden de ViZkes gestaag uit tot een gevestigde waarde in Zürich. Met de opmars van het
                            aantal
                            leden werden ook steeds meer
                            ViZkes bijeenkomsten georganiseerd en daarbij was bijkomende hulp uiteraard meer dan welkom.
                            Doorheen de jaren werden de ViZkes dan ook bestuurd door verschillende enthousiaste
                            Vlamingen.
                            Wij geven jullie hieronder een overzicht. Mede dankzij al deze helpende handen staan de
                            ViZkes
                            heden ten dage bekend voor de vele gezellige
                            Vlaamse avonden in Zürich en groeiden events zoals “ViZkes gaan pintjes drinken”,
                            KerstViZkes,
                            Q’ViZkes (de jaarlijkse ViZkes quiz) uit tot ware traditiemomenten.
                        </p>
                    </div>
                    <br/><br/>
                    <h2><i>Wij hopen van harte dat deze tradities nog lang zullen blijven bestaan en dat de ViZkes de
                        Vlaamse gezelligheid verder over het Zwitserse landschap heen mag spreiden.</i></h2>
                </div>
                <Title title="Tijdslijn Bestuursleden"
                       subTitle="een klein overzicht van onze bestuursleden doorheen de jaren."/>
                <div style={{textAlign: "justify", left: "auto", right: "auto", margin: '2em 0'}}>
                    <p>Hier komt een tijdlijn...</p>
                </div>
            </div>
        </section>
    </Layout>
;