import {useState} from "react";
import {FacebookEvent} from "../../api/viz-api/model";
import {getFacebookEvents} from "../../api/viz-api/viz-api";

export const useFacebookEvents = (size: number) => {

    const [fetchResult, setFetchResult] = useState<FacebookEvent[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);


    const fetchEvents = async () => {
        const events = await getFacebookEvents(size);
        setFetchResult(events);
        setIsLoaded(true);
    };

    return {
        fetch: fetchEvents,
        fetchResult,
        isLoaded
    }
};