import React, {FC} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import './style/style.css';
import {Home} from "./pages/home";
import {Archive} from "./pages/archive";
import {HistoryPage} from "./pages/history";
import {NewsLetters} from "./pages/newsletters";
import {Admin} from "./pages/admin";

export const App: FC = () => {
    return (
        <Router>
            <>
                {[
                    {
                        path: "/archive",
                        exact: true,
                        component: Archive
                    },
                    {
                        path: "/history",
                        exact: true,
                        component: HistoryPage
                    },
                    {
                        path: "/newsletters",
                        exact: true,
                        component: NewsLetters
                    },
                    {
                        path: "/admin",
                        exact: true,
                        component: Admin
                    },
                    {
                        path: "/",
                        exact: true,
                        component: Home
                    }
                ].map((route, index) =>
                    (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={(props: any) => {
                                return (
                                    <route.component {...props} />
                                );
                            }}
                        />
                    ))}
            </>
        </Router>
    );
};
