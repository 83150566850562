import React, {FC} from "react";
import classNames from "classnames";

interface BurgerMenuProps {
    onClick: () => void;
    open: boolean;
}

export const BurgerMenu: FC<BurgerMenuProps> = ({onClick, open}) =>
    (
        <div className='burger-menu' onClick={onClick}>
            <div className={classNames("menu-icon", {'menu-icon-active': open})}/>
        </div>
    );