import React, {FC, useEffect, useRef} from "react";
import classNames from "classnames";

interface ModalProps {
    show: boolean;
    close: () => void;
}

export const Modal: FC<ModalProps> = ({children, show, close}) => {
    const modalStyle = classNames('mymodal', {open: show});
    const backdropStyle = classNames('backdrop', {'backdrop-open': show});

    const modalRef = useRef<any>();

    useEffect(() => {
        modalRef.current.scrollTo(0, 0);
    });

    return <div className='mymodal-wrapper'>
        <div className={backdropStyle}></div>
        <div ref={modalRef} className={modalStyle}>
            <button className="modal-close-button" onClick={close}>sluit</button>
            <div className='mymodal-content-wrapper'>
                <div style={{flex: '2 1 0'}}>
                    {children}
                </div>
            </div>
            <div style={{textAlign: "right", marginRight: '2em'}}>
                <button className="modal-close-button" onClick={close}>sluit</button>
            </div>
        </div>
    </div>;
};