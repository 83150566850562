import React, {FC} from "react";
import {BoardMember} from "../data";
import {Title} from "../components/title";

interface BoardMembersProps {
    members: BoardMember[];
}

const renderMembers = (members: BoardMember[]) =>
    <div className='board-members'>
        {members.map(({name, description, image}, index) =>
            <article className="board-member" key={name}>
                <img src={image}
                     className='board-member-image' alt=""/>
                <div className="board-member-name">
                    <h4>{name}</h4>
                </div>
            </article>
        )
        }
    </div>;

export const BoardMembers: FC<BoardMembersProps> = ({members}) =>
    <>
        <Title title="Ons Bestuur" subTitle={"De " + members.length + " personen die alles in goede banen leiden."}/>
        {renderMembers(members)}
    </>;