import React, {FC} from "react";
import {links} from "../data";
import {useScreen} from "../hooks/use-screen";

interface Group {
    name: string;
    description: string;
    image: string;
    link: string;
}

const {facebook: {subgroups: {vizfit, bizzkes, carpooling, vizkids}}} = links;

const subGroups: Group[] = [
    {
        name: 'ViZFit',
        description: 'Deze groep dient als een platform om sportieve ervaringen te delen, af te spreken om een sportieve uitdaging samen aan te gaan of wilde sportieve ideeën te posten.',
        image: require('../img/subgroups/vizfit.jpg'),
        link: vizfit
    },
    {
        name: 'BiZZkes',
        description: 'Deze groep dient als een platform voor ViZkes om zichzelf en hun professionele bezigheid voor te stellen, (tweedehands)zaken weg te geven/verkopen of andere activiteiten die commercieel beschouwd kunnen worden.',
        image: require('../img/subgroups/bizzkes.jpg'),
        link: bizzkes

    },
    {
        name: ' ViZkes Carpooling',
        description: 'Deze groep dient als een platform om afspraken te maken inzake carpooling of het vervoer van goederen tussen België en Zwitserland.',
        image: require('../img/subgroups/vizkes-carpoolen.jpg'),
        link: carpooling
    },
    {
        name: 'ViZKids',
        description: 'Deze groep dient als platform voor ViZkes met kinderen om af te spreken, ervaringen te delen of bv. babysits te regelen.',
        image: require('../img/subgroups/vizkids.jpg'),
        link: vizkids
    }
];

export const SubGroups: FC = () => {

    const {isLargeScreen} = useScreen();

    return (
        <div className="subgroups-body">
            {subGroups.map(({name, description, image, link}) => {
                return (
                    <article className="subgroup-item" key={name}>
                        <a href={link} target="_blank"><img src={image}
                                                            className="mt-5" alt=""/></a>
                        <span className="subgroup-title"><a href={link} target="_blank">{name}</a></span>
                        <div className='subgroup-description'>
                            <div>{description}</div>
                            <a href={link} target="_blank" className="btn-read-more">Bezoek</a>
                        </div>
                    </article>
                );
            })}
        </div>
    );
};