import {isNil} from 'lodash';
import {useState} from "react";
import {getImageBlob, getImages} from "../../api/viz-api/viz-api";
import {Image} from "../../api/viz-api/model";

export interface BannerImage {
    source: string;
    name: string;
    description: Description;
}

export interface Zoom {
    start: string;
    end: string;
    offsetStart: string;
    offsetEnd: string;
}

export interface Description {
    title: string;
    subTitle?: string;
    superTitle?: string;
    color?: string;
    fit?: string;
    position?: string;
    zoom?: Zoom;
}

export const parseDescription = (description: string): Description => {
    try {
        return JSON.parse(description.trim()) as Description
    } catch (e) {
        console.log(e);
    }
    return {title: ''}
};

export const useGoogleFolder = (folderId: string, pageSize?: number) => {

    const [pageToken, setPageToken] = useState<string>();
    const [fetchResult, setFetchResult] = useState<Image[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [folderIdentifier, setFolderIdentifier] = useState<string>(folderId);

    const imagesRequest = (pageToken?: string): () => Promise<void> => {
        return async () => {
            setLoading(true);
            const result = await getImages(folderIdentifier, pageSize, pageToken);
            if (isNil(result)) {
                setFetchResult([]);
            } else {
                const {images, nextPage} = result;
                setPageToken(nextPage);
                setLoading(false);
                setFetchResult(prevState => [...prevState, ...images]);
            }
        };
    };

    const loadImages = async (): Promise<BannerImage[]> => {
        if (!isNil(fetchResult)) return Promise.all(fetchResult.map(async ({id, name, description}) => {
            const source: string = await getImageBlob(id);
            return {
                name: name,
                description: parseDescription(description),
                source,
            };
        }));
        return []
    };

    return {
        fetch: imagesRequest(),
        fetchResult,
        getNextPage: imagesRequest(pageToken),
        hasNextPage: () => !loading && !isNil(pageToken),
        isLoading: loading,
        loadImages
    }
};