import React, {FC, useState} from "react";
import {subscribeToNewsLetter} from "../api/viz-api/viz-api";
import {isEmpty} from 'lodash';
import {useScreen} from "../hooks/use-screen";

interface NewsLetterFormProps {
    onSubscribed: () => void;
}

export const NewsLetterForm: FC<NewsLetterFormProps> = ({onSubscribed}) => {
    const [email, setEmail] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();

    const subscribe = async () => {
        const validate = () => {
            if (isEmpty(firstName) || isEmpty(lastName) || isEmpty(email)) {
                throw new Error('Gelieve all velden in the vullen aub');
            }
        };
        try {
            validate();
            await subscribeToNewsLetter(email, firstName, lastName);
            onSubscribed();
        } catch (e) {
            alert(e.message);
        }
    };

    const {isMobileDevice} = useScreen();

    return <form id="mailchimp-subscription-form-footer" style={{display: 'flex', flexDirection: 'column'}}>
        <input type="email" name="email" placeholder="Jouw email adres"
               onChange={event => setEmail(event.target.value)}
               id="mce-EMAIL-footer"/>
        <input type="text" name="firstName" placeholder="Voornaam"
               onChange={event => setFirstName(event.target.value)}
               id="mce-EMAIL-footer"/>
        <input type="text" name="lastName" placeholder="Achternaam"
               onChange={event => setLastName(event.target.value)}
               id="mce-EMAIL-footer"/>
        <span className="input-group-btn">
            <button type="button" onClick={subscribe}>Schrijf je in</button>
        </span>
    </form>;
}