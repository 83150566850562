import React, {FC} from "react";
import classNames from "classnames";

interface LogoProps {
    text?: string;
    containerClassName?: string;
    logoClassName?: string;
}

export const Logo: FC<LogoProps> = ({containerClassName, logoClassName, text = 'Vlamingen in Zurich'}) =>
    <div className={classNames('logo-container', containerClassName)}>
        <div className={classNames('logo', logoClassName)}>
            {text}
        </div>
    </div>;