import React, {FC, ReactElement} from "react";

interface TitleProps {
    title: string;
    subTitle?: string | ReactElement;
    paragraph?: string | ReactElement;
}

export const Title: FC<TitleProps> = ({title, subTitle, paragraph}) => {
    const [firstWord, ...restOfTitle] = title.split(' ');
    return <div className="main-title">
        <div>
            <span className="first-word"><h1>{firstWord}</h1></span> <span className="other-words"><h1>{restOfTitle.join(' ')}</h1></span>
        </div>
        <h2>{subTitle}</h2>
        <h3>{paragraph}</h3>
    </div>;
};