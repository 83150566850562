import React, {FC, useState} from "react";
import {GoogleFolder} from "../components/google-folder";
import {Layout} from "../layout/Layout";
import {RouteComponentProps} from "react-router";
import {Title} from "../components/title";

//const folderId = '1seH2W5bCKI85Hv8uRIuwWBF0nVrwH9Jb';
const folderId = '1dLyXZCpY0_XLGrBxzADp2hSvFmOaeMWV';

export const Archive: FC<RouteComponentProps> = ({...props}) => {
    const [galleryOpened, setGalleryOpened] = useState<boolean>(true);
    return (
        <Layout {...props}>
            <section>
                <div className="pb-0 container">
                    <Title title="Ons Archief"
                           subTitle="Hieronder vinden jullie een greep uit het foto archief van de periode 2011 tem 2018. Voor meer recentere fotos verwijzen we jullie graag door naar onze Instagram pagina."/>
                </div>
            </section>
            <div>
                <GoogleFolder id={folderId}/>
            </div>
        </Layout>
    );
};