import React from 'react';

const defaultProps = {
    show: true,
};

const LoadingSpinner = ({show}) => (show && (
    <div className="loading-spinner"/>
));

LoadingSpinner.defaultProps = defaultProps;

export default LoadingSpinner;
