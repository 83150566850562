import React, {FC, useEffect, useState} from "react";
import {isNil} from 'lodash';
import classNames from "classnames";
import {RouteComponentProps} from "react-router";
import {Logo} from "./logo";
import {links} from "../data";
import {useScreen} from "../hooks/use-screen";
import {useMediaPredicate} from "react-media-hook";

export interface MenuItem {
    id: string;
    title: string;
}

interface HeaderProps {
    menuItems: MenuItem[];
}

export const Header: FC<HeaderProps & RouteComponentProps> = ({menuItems, history}) => {
    const [active, setActive] = useState<string>('home');

    const scrollTo = (id: string) => () => {
        const element = document.getElementById(id);
        if (!isNil(element)) {
            setActive(id);
            element.scrollIntoView({behavior: "smooth", block: "end"});
        } else {
            history.push('/');
        }
    };

    const isActive = (id: string) => active === id;

    const {whatsapp, instagram, facebook: {group}} = links;
    const {isLargeScreen} = useScreen();

    const iconClassnames = classNames("social-icons", "mt-10", {'icon-md': isLargeScreen}, {'icon-sm': !isLargeScreen});

    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        function onScroll(e: any) {
            setScrollTop(e.target.documentElement.scrollTop);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const toggleMenu = () => setShowMenu(!showMenu);
    const sidebarClassname = classNames('sidebar', {'show-sidebar': showMenu});
    const backdropClassname = classNames('sidebar-backdrop', {'show-sidebar-backdrop': showMenu});

    const headerClassName = classNames(
        'header-nav',
        'navbar-white',
        'navbar-transparent',
        'bg-transparent-1',
        'navbar-sticky-animated',
        {'animated-active': scrollTop < 50}
    );

    const showArchiveContracted = useMediaPredicate("(max-width: 1885px)");

    return (
        <header style={{position: "sticky", top: 0, zIndex: 1000}}>
            <div
                className={headerClassName}>
                <nav id="menuzord-right" className="menuzord"
                     style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                    <a href="/" style={{flex: '1 1 0'}}>
                        <Logo/>
                    </a>
                    <div style={{display:'flex', flexDirection:"column", flex:'2 1 0'}}>
                        <ul className="menuzord-menu onepage-nav">
                            {menuItems.map(({id, title}) =>
                                <li key={id} className={classNames({active: isActive(id)})} onClick={scrollTo(id)}>
                                    <a
                                        href={`#${id}`}>{title}</a></li>)
                            }
                        </ul>
                        {

                            showArchiveContracted ? <ul className='archive-items'>
                                <li><a href='/newsletters'>Nieuwsbrieven</a></li>
                                <li><a href='/archive'>Archief</a></li>
                                <li><a href='/history'>Geschiedenis</a></li>
                            </ul> : null

                        }
                    </div>
                    {
                        showArchiveContracted ? null : <ul className='archive-items'>
                            <li><a href='/newsletters'>Nieuwsbrieven</a></li>
                            <li><a href='/archive'>Archief</a></li>
                            <li><a href='/history'>Geschiedenis</a></li>
                        </ul>
                    }
                    <ul className={iconClassnames} style={{display: "flex", flexDirection: "row"}}>
                        <li><a style={{color: 'white'}} title="Facebook" href={group} target="_blank"><i
                            className="fa fa-facebook"/></a></li>
                        <li><a style={{color: 'white'}} title="Whatsapp" href={whatsapp} target="_blank"><i
                            className="fa fa-whatsapp"/></a>
                        </li>
                        <li><a style={{color: 'white'}} title="Instagram" href={instagram} target="_blank"><i
                            className="fa fa-instagram"/></a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};