import React, {PureComponent} from 'react';

import classnames from 'classnames';

import defaultPhrases from '../../defaultPhrases';
import noop from '../../utils/noop';

const defaultProps = {
    isOpened: true,
    onPress: noop,
    phrases: defaultPhrases,
};

class TogglePhotoList extends PureComponent {
    render() {
        const {
            isOpened,
            onPress,
            phrases: {
                showPhotoList: showLabel,
                hidePhotoList: hideLabel,
            },
        } = this.props;

        const label = isOpened ? hideLabel : showLabel;

        const className = classnames(
            'gallery-thumbnails--toggle',
            isOpened ? 'hide' : 'open',
        );

        return (
            <button
                type="button"
                className={className}
                onClick={onPress}
            >
                {label}
            </button>
        );
    }
}

TogglePhotoList.defaultProps = defaultProps;

export default TogglePhotoList;
