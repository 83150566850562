import {useMediaPredicate} from "react-media-hook";

export interface ScreenState {
    isMobileDevice: boolean;
    isTablet: boolean;
    isLaptop: boolean;
    isDesktop: boolean;
    isLargeScreen: boolean;
}

export const useScreen = (): ScreenState => {

    const isMobileDevice = useMediaPredicate("(max-width: 480px)");
    const isTablet = useMediaPredicate("(max-width: 768px)");
    const isLaptop = useMediaPredicate("(max-width: 1024px)");
    const isDesktop = useMediaPredicate("(max-width: 1200px)");
    const isLargeScreen = useMediaPredicate("(min-width: 1201px)");

    return {
        isMobileDevice,
        isTablet,
        isLaptop,
        isDesktop,
        isLargeScreen
    }
};