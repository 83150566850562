import React, {FC, useCallback, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Layout} from "../layout/Layout";
import {Campaign, CampaignPage} from "../api/viz-api/model";
import {getNewsLetterHtmlRequest, getNewsLetters} from "../api/viz-api/viz-api";
import {isEmpty, isNil} from 'lodash';
import moment from "moment-with-locales-es6";
import Iframe from "react-iframe";
import {Modal} from "../components/modal/modal";
import {useModal} from "../components/modal/use-modal";
import {Title} from "../components/title";

var parse = require('html-react-parser');

export const NewsLetters: FC<RouteComponentProps> = ({...props}) => {
    const [campaignPage, setCampaignPage] = useState<CampaignPage>();
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
    const [content, setContent] = useState<string>('');
    const {closeModal, showModal, openModal} = useModal();

    const renderCampaignPage = useCallback((campaignPage: CampaignPage) => {
        const sorted = campaignPage.campaigns
            .filter(({status}) => status === 'sent')
            .sort((a, b) => moment(a.send_time).isBefore(moment(b.send_time)) ? 1 : -1)

        let year = moment(sorted[0].send_time).locale('nl').format('YYYY');
        return <>
            {sorted
                .map((campaign) => {
                    const currentYear = moment(campaign.send_time).locale('nl').format('YYYY');
                    const marginTop = currentYear !== year ? '3em' : '1em';
                    year = currentYear !== year ? currentYear : year;
                    const {long_archive_url, id, send_time, settings: {preview_text, subject_line, title}} = campaign;
                    return <div key={id} className="newsletter-display"
                                onClick={() => {
                                    setSelectedCampaign(campaign);
                                    openModal();
                                }}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: '20em auto',
                                    gridTemplateRows: 'repeat(2, auto)'
                                }}>
                        <p style={{marginTop}}>{moment(send_time).locale('nl').format('D MMMM YYYY')}</p>
                        <p style={{marginTop, cursor: "pointer"}}>{!isEmpty(title) ? title : subject_line}</p>
                    </div>;
                })}
        </>;
    }, []);

    useEffect(() => {
        const loadData = async () => {
            const campaignPage = await getNewsLetters();
            setCampaignPage(campaignPage);
        };
        loadData();
    }, []);

    return <Layout {...props}>
        <section>
            <div className="pb-0 container">
                <Title title="Onze Nieuwsbrieven" subTitle=""/>
                <div style={{
                    textAlign: "justify",
                    left: "auto",
                    right: "auto",
                    backgroundColor: 'white',
                    marginTop: '1em'
                }}>
                    {!isNil(campaignPage) ? renderCampaignPage(campaignPage) : <></>}
                </div>
            </div>
        </section>
        {!isNil(selectedCampaign) ?
            <Modal show={showModal} close={() => {
                setSelectedCampaign(undefined);
                closeModal();
            }}>
                {!isNil(selectedCampaign) ?
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <h1 style={{textAlign: "center"}}>{selectedCampaign.settings.title}</h1>
                        <Iframe className='newsletters' width='100%' height='100%'
                                url={getNewsLetterHtmlRequest(selectedCampaign.id)}/>
                    </div>
                    : <></>}
            </Modal> : <></>
        }

    </Layout>;
};