import React, {FC, useEffect, useState} from "react";
import {DebugInfo} from "../api/viz-api/model";
import {getTokenInfo} from "../api/viz-api/viz-api";
import {RouteComponentProps} from "react-router";
import {isNil} from "lodash";

export const Admin: FC<RouteComponentProps> = () => {
    const [info, setInfo] = useState<DebugInfo>();
    useEffect(() => {
        const getInfo = async () => {
            const infoFromApi = await getTokenInfo();
            setInfo(infoFromApi);
        };
        getInfo();
    }, []);

    return (
        isNil(info) ? <></> :
            <div style={{
                margin: '1rem 1rem',
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gridRow: 'auto',
                gridGap: '1rem'
            }}>
                <div><b>Valid</b></div>
                <div>{info.is_valid ? 'yes' : 'no'}</div>
                <div><b>Expires at</b></div>
                <div>{new Date(info.expires_at).toUTCString()}</div>
                <div><b>Data Access Expires at</b></div>
                <div>{new Date(info.data_access_expires_at).toUTCString()}</div>
            </div>
    );
}
