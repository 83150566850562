import React, {FC, useState} from "react";
import {Slider} from "../components/slider";
import {About} from "../sections/about";
import {Events} from "../sections/events";
import {SubGroups} from "../sections/sub-groups";
import {SaveTheDate} from "../sections/save-the-date";
import {BoardMembers} from "../sections/board-members";
import {useHome} from "../hooks/home/use-home";
import {Instagram} from "../sections/instagram";
import {RouteComponentProps} from "react-router";
import {Layout} from "../layout/Layout";
import {boardMembers, links, menuItems} from "../data";
import {Title} from "../components/title";
import {SubSection} from "../components/sub-section";
import {NewsLetterForm} from "../components/newsletter-form";
import { isNil } from "lodash";

export const Home: FC<RouteComponentProps> = ({...props}) => {
    const {facebookEvents, isLoaded, instagramImages} = useHome(10, 10);

    const {footer: {bass, viw, ambasade}, facebook: {group}, whatsapp, instagram} = links;
    const [subscribed, setSubscribed] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const handleSubscribed = () => {
        setSubscribed(true);
        setMessage('Bedankt voor de inschrijving.');
        setTimeout(() => setMessage(''), 5000)
    };

    return (
        <Layout {...props} menuItems={menuItems}>
            <div className='section-container'>
                <section className="banner-section">
                    <Slider/>
                </section>
                <section className="container about-section">
                    <About/>
                </section>
                <section id="events" className="container events-section">
                    <div>
                        <Title title='Onze Evenementen' subTitle='Ontdek ze allemaal hier, inschrijven indien nodig kan via onze fb pagina of per mail.'/>
                    </div>
                    <div>
                        {isLoaded &&
                        <Events facebookEvents={facebookEvents} notLoaded={isLoaded && isNil(facebookEvents)}/>}
                    </div>
                </section>
                <SubSection>
                    <div>
                        <Title title="Onze Subgroepen" subTitle={<em>Bekijk onze subgroepen <span
                            className="text-theme-colored">op facebook</span>.</em>} paragraph="Aangezien een aantal specifieke onderwerpen regelmatig
                                terugkomen zijn
                                hiervoor subgroepen aangemaakt,
                                zodoende dat jullie elkaar makkelijker terugvinden."/>
                        <SubGroups/>
                    </div>
                    <div className='save-the-date-section'>
                        <Title title="Save The Date" subTitle={<em>Noteer alvast <span
                            className="text-theme-colored">volgende data</span> in jouw
                            agenda voor opkomende
                            events.</em>} paragraph="Meer info volgt eens het event officieel aangemaakt is (op
                                Facebook)."/>
                        {isLoaded && <SaveTheDate facebookEvents={facebookEvents}/>}
                    </div>
                </SubSection>
                <section id="instagram" className='container'>
                    <Instagram instagramImages={instagramImages}/>
                </section>
                <section className="container board-section" id='board'>
                    <BoardMembers
                        members={boardMembers}/>
                </section>
                <section className='container follow-section'>
                    {subscribed ? message :
                        <>
                            <h2>Schrijf je in op onze nieuwsbrieven</h2>
                            <div className="widget dark">
                                <NewsLetterForm onSubscribed={handleSubscribed}/>
                            </div>
                        </>}
                    <h2>Volg ons op sociale media</h2>
                    <div className="social">
                        <a href={group} target="_blank" style={{paddingRight: '1em'}}><i
                            className="fa fa-facebook text-theme-colored m-0"/></a>
                        <a href={whatsapp} style={{paddingRight: '1em'}}
                           target="_blank"><i
                            className="fa fa-whatsapp text-theme-colored m-0"/></a>
                        <a href={instagram} style={{paddingRight: '1em'}}
                           target="_blank"><i
                            className="fa fa-instagram text-theme-colored m-0"/></a>
                    </div>
                </section>
            </div>
        </Layout>
    )
};