import {useEffect} from "react";
import {useFacebookEvents} from "../facebook/use-facebook-events";
import {useInstagramImages} from "../instagram/use-instagram-images";
import {FacebookEvent, InstagramImage} from "../../api/viz-api/model";

export interface HomeState {
    facebookEvents: FacebookEvent[],
    isLoaded: boolean,
    instagramImages: InstagramImage[]
}

export const useHome = (instagramImagesSize: number, facebookEventsSize: number): HomeState => {
    const {fetch: loadFacebookEvents, fetchResult: facebookEvents, isLoaded: isFacebookEventsLoaded} = useFacebookEvents(facebookEventsSize);

    useEffect(() => {
        loadFacebookEvents();
    }, []);

    const isLoaded = isFacebookEventsLoaded;

    const {fetch: getInstagramImages, fetchResult: instagramImages, isLoading: isInstagramImagesLoading} = useInstagramImages(instagramImagesSize);

    useEffect(() => {
        getInstagramImages();
    }, []);

    return {
        facebookEvents,
        isLoaded,
        instagramImages
    }
};