import {Image} from "./model";

const dummyImage: Image = {
    id: '123',
    description: '{\"title\":\"Foto\'s kunnen momenteel niet geladen worden.\"}',
    name: 'name:',
    kind: '',
    webContentLink: ''
};

export const dummyImages = [dummyImage];
export const dummyBlob = new Blob(["<htmltest</html>"], {type: 'text/html'});