import React, {FC, useState} from "react";
import classNames from "classnames";
import {Header, MenuItem} from "../components/header";
import {Footer} from "../components/footer";
import {RouteComponentProps} from "react-router";
import {useScreen} from "../hooks/use-screen";
import {Logo} from "../components/logo";
import {isNil} from "lodash";
import {archiveMenuItems} from "../data";
import {BurgerMenu} from "../components/burger-menu";

interface LayoutProps {
    show?: boolean;
    menuItems?: MenuItem[];
}

export const Layout: FC<LayoutProps & RouteComponentProps> = ({menuItems = [], show = true, children, ...props}) => {
    const screen = useScreen();
    const {isLaptop} = screen;

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const toggleMenu = () => setShowMenu(!showMenu);
    const sidebarClassname = classNames('sidebar', {'show-sidebar': showMenu});
    const backdropClassname = classNames('sidebar-backdrop', {'show-sidebar-backdrop': showMenu});

    const scrollTo = (id: string) => () => {
        const element = document.getElementById(id);
        if (!isNil(element)) {
            element.scrollIntoView({behavior: "smooth", block: "end"});
        }
    };

    return !show ? null : (
        <>
            {isLaptop ? (
                <div className='mobile-menu'>
                    <div className='menu-toggle'>
                        <BurgerMenu onClick={toggleMenu} open={showMenu}/>
                        <div className={sidebarClassname}>
                            <ul className='sidebar-list'>
                                {menuItems.map(({title, id}) =>
                                    <li key={title} className='side-menu-item' onClick={() => {
                                        scrollTo(id)();
                                        toggleMenu();
                                    }}>
                                        {title}
                                    </li>)}
                            </ul>
                            <ul className="archive-menu">
                                {archiveMenuItems.map(({title, id}) =>
                                    <li key={title} className='archive-menu-item'>
                                        <a href={id}>{title}</a>
                                    </li>)}
                            </ul>
                        </div>
                    </div>
                    <a href="/">
                        <div className='mobile-menu-logo'><Logo containerClassName='layout-logo-container' logoClassName='layout-logo'/></div>
                    </a>
                </div>
            ) : <Header menuItems={menuItems} {...props} />}
            {children}
            <Footer screen={screen}/>
            <a className="scrollToTop" href="#"><i className="fa fa-angle-up"></i></a>
        </>
    )
};