import React, {FC, useCallback, useMemo, useState} from "react";
import classNames from "classnames";
import {isNil, noop} from 'lodash';
import {links} from "../data";
import moment from "moment-with-locales-es6";
import ReactBnbGallery from "../components/gallery";
import {useScreen} from "../hooks/use-screen";


interface InstagramImage {
    media_url?: string;
    caption?: string;
    timestamp?: number;
}

interface InstagramProps {
    instagramImages: InstagramImage[];
}

const getBorderRadius = (index: number): string => {
    const radiusValue = '20%';
    switch (index) {
        case 0:
            return `${radiusValue} 0 0 0`;
        case 2:
            return `0 ${radiusValue} 0 0`;
        case 6:
            return `0 0 0 ${radiusValue}`;
        case 8:
            return `0 0 ${radiusValue} 0`;
        default:
            return '0 0 0 0';
    }
};

const isLogo = (index: number, isMobileDevice: boolean) => isMobileDevice ? index === 1 : index === 4;

const renderItem = ({media_url}: InstagramImage, index: number, onClick: () => void, selected: boolean = false) =>
    <div onClick={onClick} key={index} className={classNames('highlight-element', `highlight-element-${index}`)}
         style={{
             cursor: 'pointer',
             backgroundImage: `url(${media_url})`
         }}>
        {media_url ?
            <div className={classNames({'highlight-zoom': !selected})}>
                <i className="fa fa-search-plus"/>
            </div> : <></>
        }
    </div>;

const renderInstagramLogo = (index: number) =>
    <div key={index} className="highlight-element-blank">
        {<>
            <header className='highlight-header-blank'>
                <h2>
                    <a target='_blank' href={links.instagram}>
                        <img alt='Instagram' style={{width: '10em'}}
                             src='https://www.instagram.com/static/images/web/mobile_nav_type_logo.png/735145cfe0a4.png'/>
                    </a>
                </h2>
            </header>
        </>
        }
    </div>;

const render = (instagramImage: InstagramImage, index: number, isMobileDevice: boolean, onClick: () => void = noop, selected: boolean = false) => isLogo(index, isMobileDevice) ? renderInstagramLogo(index) : renderItem(instagramImage, index, onClick, selected);

export const Instagram: FC<InstagramProps> = ({instagramImages}) => {
    const {isTablet} = useScreen();
    const [selectedImage, setSelectedImage] = useState<InstagramImage>();
    const isSelected = (instagramImage: InstagramImage) => selectedImage === instagramImage;

    const [image1, image2, image3, image4, image5, image6] = instagramImages;
    const items: InstagramImage[] = isTablet ? [image1, {caption: ''}, image2] : [image1, image2, image3, {caption: ''}, {caption: ''}, {caption: ''}, image4, image5, image6];

    const album = useMemo(() => instagramImages.length > 0 ? instagramImages.map(image => ({
        id: image.timestamp,
        photo: image.media_url!,
        caption: <span className='caption-modal'>{image.caption}</span>,
        subcaption: <span
            className='subcaption-modal'>{moment(image.timestamp).locale('nl').format('D MMMM yyyy')}</span>
    })) : [], [instagramImages]);

    const findIndex = useCallback(() => {
        return album.map((value, index) => value.id === selectedImage!.timestamp ? index : -1).filter(value => value !== -1)[0];
    }, [selectedImage, album]);

    return (
        instagramImages.length > 0 ? <div className="highlight-section">
            {instagramImages.length === 0 && 'Kan instagram foto\'s momenteel niet laden...'}
            {instagramImages.length > 0 && items.map((value, index) => {
                return render(value, index, isTablet, () => setSelectedImage(value), isSelected(value));
            })}
            {selectedImage && <ReactBnbGallery photos={album}
                                               onClose={() => setSelectedImage(undefined)} show={!isNil(selectedImage)}
                                               activePhotoIndex={findIndex()}
                                               wrap={false}/>}
        </div> : <></>
    )
};