import {VIZ_API} from "../../environment";
import {
    CampaignPage,
    DebugInfo,
    FacebookEvent,
    FetchRequest,
    Image,
    InstagramImage,
    InstagramMedium,
    Page
} from "./model";
import {dummyBlob, dummyImages} from "./mock";
import {isEmpty} from 'lodash';

export const getImages = async (folderId: string, pageSize?: number, pageToken?: string): Promise<Page<Image>> => {
    const body = JSON.stringify({pageSize, pageToken});

    return await withFallBack(() => fetch(`${VIZ_API}/google/folder/${folderId}`, {
        method: 'POST',
        body,
        headers: {
            "Content-Type": "application/json"
        }
    }).then(value => value.json()), undefined, {
        images: dummyImages,
        nextPage: ''
    });
};

export const getImageBlob = async (imageId: string): Promise<string> => {
    const blob = await withFallBack(() => fetch(`${VIZ_API}/google/image/${imageId}`).then(value => value.blob()), undefined, dummyBlob);
    return URL.createObjectURL(blob);
};

export const getFacebookEvents = async (limit: number): Promise<FacebookEvent[]> => {
    try {
        return await fetch(`${VIZ_API}/facebook/events/${limit}`).then(value => value.json());
    } catch (e) {
        return Promise.resolve([]);
    }
};

export const getInstagramMedia = async (limit: number): Promise<InstagramMedium[]> => withFallBack(() => fetch(`${VIZ_API}/instagram/media/${limit}`).then(value => value.json()), 'instagramMedia');

export const getInstagramImage = async (imageId: string): Promise<InstagramImage> => withFallBack(() => fetch(`${VIZ_API}/instagram/image/${imageId}`).then(value => value.json()), `instagramImage${imageId}`);

export const getNewsLetters = async (): Promise<CampaignPage> => await fetch(`${VIZ_API}/email/campaigns`).then(value => value.json());

export const getNewsLetterHtmlRequest = (id: string) => `${VIZ_API}/email/campaigns/${id}/content`;

export const subscribeToNewsLetter = async (email?: string, firstName?: string, lastName?: string): Promise<void> => {
    if (!isEmpty(email) && !isEmpty(firstName) && !isEmpty(lastName)) {
        const body = JSON.stringify({email, firstName, lastName});

        const response = await fetch(`${VIZ_API}/email/subscribe`, {
            method: 'POST',
            body,
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }
};

export const getTokenInfo = async (): Promise<DebugInfo> => {
    return await fetch(`${VIZ_API}/facebook/debug`).then(value => value.json());
}

const withFallBack = async <T>(fetch: FetchRequest<T>, storageKey?: string, fallback?: T) => {
        try {
            return await fetch();
        } catch (e) {
            console.log(e);
        }
    }
;