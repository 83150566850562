import {MenuItem} from "../components/header";

export interface IntroductionItem {
    title?: string;
    description: string;
    link: string;
    source: string;
}

export interface BoardMember {
    name: string;
    description?: string;
    image: string;
}

export const menuItems: MenuItem[] = [
    {id: 'events', title: 'Evenementen'},
    {id: 'instagram', title: 'Impressies'},
    {id: 'board', title: 'Ons Bestuur'},
    {id: 'footer', title: 'Contact'}
];

export const archiveMenuItems: MenuItem[] = [
    {id: 'archive', title: 'Foto Archief'},
    {id: 'history', title: 'Geschiedenis'},
    {id: 'newsletters', title: 'Nieuwsbrieven'},
];

export const boardMembers: BoardMember[] = [{
    name: 'Petra Scheir',
    image: require('../img/avatars/Petra.jpg'),
    description: 'Bestuurslid sinds 2016'
}, {
    name: 'Michaël Vincke',
    image: require('../img/avatars/Michael.jpg'),
    description: 'Bestuurslid sinds 2018'
}, {
    name: 'Bernard Van der Stichele',
    image: require('../img/avatars/Bernard.jpg'),
    description: 'Bestuurslid sinds 2018'
}, {
    name: 'Maarten De bakker',
    image: require('../img/avatars/Maarten.jpg'),
    description: 'Bestuurslid sinds 2019'
}, {
    name: 'Pieter De Maertelaere',
    image: require('../img/avatars/Pieter.jpg'),
    description: 'Bestuurslid sinds 2019'
},
    {
        name: 'Charlotte Van Nueten',
        image: require('../img/avatars/Charlotte_hoch.jpg'),
        description: 'Bestuurslid sinds 2021'
    }];


export const links = {
    facebook: {
        group: 'https://www.facebook.com/groups/ViZkes',
        events: 'https://www.facebook.com/groups/ViZkes/events',
        subgroups: {
            bizzkes: 'https://www.facebook.com/groups/2215669962025705',
            vizfit: 'https://www.facebook.com/groups/ViZFit',
            carpooling: 'https://www.facebook.com/groups/1940009979549811',
            vizkids: 'https://www.facebook.com/groups/295212354685262'
        }
    },
    instagram: 'https://www.instagram.com/viz_kes',
    whatsapp: 'https://chat.whatsapp.com/Fqdv5oAAWrd5cAastjcuoo',
    footer: {
        mzs: 'https://magischezwitsersesteden.be',
        ambasade: 'https://switzerland.diplomatie.belgium.be/nl',
        viw: 'https://www.viw.be/nl',
        bass: 'https://bassnational.ch',
        ubu: 'https://www.ubu-zh.ch/nl'
    }
};

export const rightItems: IntroductionItem[] = [
    {
        description: 'Bekijk al onze vorige nieuwsbrieven.',
        link: 'Lees ze hier',
        source: '/newsletters',
    }
];

export const leftItems: IntroductionItem[] = [
    {
        description: 'In ons archief vind je een aantal activiteiten terug met leuke fotos.',
        link: 'Klik hier',
        source: '/archive',
    },
    {
        description: 'De ViZkes zijn opgericht in 2011. Lees er meer over in onze geschiedenis.',
        link: 'Klik hier',
        source: '/history',
    }
];