import React, {FC, useEffect, useRef} from "react";
import {isEmpty, isNil} from 'lodash';
import {useScreen} from "../hooks/use-screen";
import {useBanner} from "../hooks/google/use-banner";

declare var jQuery: any;

export const Slider: FC = () => {
    const {images} = useBanner();

    const myRef = useRef<any>();
    const {isLargeScreen} = useScreen();

    useEffect(() => {
        jQuery(myRef.current).show().revolution({
            sliderType: "standard",
            dottedOverlay: "none",
            delay: 5000,
            navigation: {
                keyboardNavigation: "on",
                keyboard_direction: "horizontal",
                mouseScrollNavigation: "off",
                onHoverStop: "off",
                touch: {
                    touchenabled: "on",
                    swipe_threshold: 75,
                    swipe_min_touches: 1,
                    swipe_direction: "horizontal",
                    drag_block_vertical: false
                }
                ,
                bullets: {
                    enable: false,
                    hide_onmobile: true,
                    style: "zeus",
                    hide_onleave: true,
                    direction: "horizontal",
                    h_align: "center",
                    v_align: "bottom",
                    h_offset: 30,
                    v_offset: 30,
                    space: 10,
                    tmp: ''
                }
                ,
                thumbnails: {
                    style: "gyges",
                    enable: false,
                    width: 80,
                    height: 80,
                    min_width: 60,
                    wrapper_padding: 0,
                    wrapper_color: "#000000",
                    wrapper_opacity: "0",
                    tmp: '<span class="tp-thumb-img-wrap">  <span class="tp-thumb-image"></span></span>',
                    visibleAmount: 10,
                    hide_onmobile: true,
                    hide_onleave: true,
                    direction: "horizontal",
                    span: false,
                    position: "inner",
                    space: 10,
                    h_align: "left",
                    v_align: "bottom",
                    h_offset: 30,
                    v_offset: 30
                }
            },
            responsiveLevels: [3000, 2048, 1024, 778],
            gridheight: [900, 600, 500, 400, 300],
            lazyType: "none",
            parallax: {
                type: "mouse+scroll",						// off, mouse, scroll, mouse+scroll
                levels: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85],
                origo: "slidercenter",				// slidercenter or enterpoint
                speed: 400,
                bgparallax: "on",
                opacity: "on",
                disable_onmobile: "off",
            },
            shadow: 0,
            disableProgressBar: "on",
            spinner: "spinner2",
            stopLoop: "on",
            stopAfterLoops: 0,
            stopAtSlide: -1,
            shuffle: "off",
            autoHeight: "off",
            hideThumbsOnMobile: "off",
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            debugMode: false,
            fallbacks: {
                simplifyAll: "off",
                nextSlideOnWindowFocus: "off",
                disableFocusListener: true
            }
        });
    }, [images]);

    const showPictureInfo = isLargeScreen;

    const vOffset = 10;

    return images.length === 0 ?
        <div className='loading-banner'><p>Foto's laden</p><i className="fas fa-spinner fa-spin"/></div> : (
            <div className="rev_slider_wrapper" style={{height: '5em'}}>
                <div className="rev_slider" ref={myRef} data-version="5.0">
                    <ul>
                        {images.map(({picture, title, supertitle, subtitle, color, fit, position, zoom}, index) => {
                            let kenBurnsEffect = !isNil(zoom) ? 'on' : 'off';
                            kenBurnsEffect = 'off';
                            const bgFit = !isNil(fit) ? fit : 'cover';
                            const bgposition = !isNil(position) ? `center ${position}` : 'center center';
                            const textColor = bgFit === 'cover' ? "#fff" : '#000';
                            const bgColor = isEmpty(title) || bgFit !== 'cover' ? 'none' : textColor === '#fff' ? 'rgba(0,0,0,0.5)' : '#fff';
                            const border = bgColor === 'none' ? 'none' : '1px solid rgba(0,0,0,0.5)';
                            const multipleWords = !isNil(title) ? title.split(' ').length > 1 : false;
                            const splitin = multipleWords ? 'chars' : 'chars';
                            return (
                                <li key={index} data-index={index} data-transition="crossfade"
                                    data-slotamount="default"
                                    data-easein="default" data-easeout="default" data-masterspeed="default"
                                    data-thumb={picture} data-rotate="0" data-saveperformance="off"
                                    data-title="Wow Factor" data-description="">
                                    <img src={picture} alt="" data-bgposition={bgposition}
                                         data-kenburns={kenBurnsEffect}
                                         data-duration="15000"
                                         data-scalestart={kenBurnsEffect === 'on' ? zoom!.start : 0}
                                         data-scaleend={kenBurnsEffect === 'on' ? zoom!.end : 0}
                                         data-offsetstart={kenBurnsEffect === 'on' ? zoom!.offsetStart : 0}
                                         data-offsetend={kenBurnsEffect === 'on' ? zoom!.offsetEnd : 0}
                                         data-bgfit={bgFit} data-bgrepeat="no-repeat" className="rev-slidebg"
                                         data-bgparallax="8" data-no-retina/>
                                    {!showPictureInfo ? <></> :
                                        <>
                                            <div className="tp-caption NotGeneric-Title tp-resizeme text-uppercase"
                                                 id={`${index}-layer-1`}
                                                 data-x="['left','left','left','left']"
                                                 data-y="['bottom','bottom','bottom','bottom']"
                                                 data-width="none"
                                                 data-height="none"
                                                 data-transform_idle="o:1;"
                                                 data-transform_in="x:-500px;opacity:0;s:1000;e:Power1.easeIn;"
                                                 data-transform_out="x:-500px;opacity:0;s:500;e:Power1.easeInOut;"
                                                 data-start="500"
                                                 data-splitin={splitin}
                                                 data-splitout="none"
                                                 data-basealign="slide"
                                                 data-responsive_offset="on"
                                                 data-elementdelay="0.01"
                                                 style={{
                                                     zIndex: 5,
                                                     whiteSpace: "nowrap",
                                                     fontSize: "2em",
                                                     lineHeight: "1em",
                                                     color: textColor,
                                                     backgroundColor: bgColor,
                                                     border,
                                                     fontWeight: "normal",
                                                     marginBottom: '.2em',
                                                     padding: '2em'
                                                 }}>{title}
                                            </div>
                                            <div className="tp-caption NotGeneric-SubTitle tp-resizeme text-uppercase"
                                                 id="rs-1-layer-2"
                                                 data-x="['left','left','left','left']"
                                                 data-y="['bottom','bottom','bottom','bottom']"
                                                 data-width="none"
                                                 data-height="none"
                                                 data-transform_idle="o:1;"
                                                 data-transform_in="x:-1000px;opacity:0;s:1000;e:Power1.easeInOut;"
                                                 data-transform_out="x:50px;opacity:0;s:500;e:Power1.easeInOut;"
                                                 data-start="1000"
                                                 data-splitin="chars"
                                                 data-splitout="none"
                                                 data-basealign="slide"
                                                 data-responsive_offset="on"
                                                 data-elementdelay="0.03"
                                                 style={{
                                                     zIndex: 6,
                                                     whiteSpace: "nowrap",
                                                     color: 'rgba(255, 255, 255, 0.9)',
                                                     fontSize: "1.2em",
                                                     marginBottom: '3em',
                                                     padding: '3em',
                                                     fontWeight: "bold"
                                                 }}>{supertitle}
                                            </div>
                                            <div className="tp-caption Photography-Textblock tp-resizeme"
                                                 id="rs-1-layer-3"
                                                 data-x="['left','left','left','left']"
                                                 data-y="['bottom','bottom','bottom','bottom']"
                                                 data-width="none"
                                                 data-height="none"
                                                 data-transform_idle="o:1;"
                                                 data-transform_in="x:-500px;opacity:0;s:1000;e:Power1.easeInOut;"
                                                 data-transform_out="x:50px;opacity:0;s:500;e:Power1.easeInOut;"
                                                 data-start="1000"
                                                 data-splitin="chars"
                                                 data-splitout="none"
                                                 data-basealign="slide"
                                                 data-responsive_offset="on"
                                                 data-elementdelay="0.03"
                                                 style={{
                                                     zIndex: 7,
                                                     whiteSpace: "nowrap",
                                                     fontSize: "1.5em",
                                                     lineHeight: "1em",
                                                     color: textColor,
                                                     fontWeight: "bold",
                                                     padding: '2.7em',
                                                     marginBottom: '-1em'
                                                 }}>{subtitle}
                                            </div>
                                        </>}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        )
};