import React from 'react';

import classnames from 'classnames';

import noop from '../../utils/noop';

import {INVERSE_COLOR, NORMAL_COLOR,} from '../../constants';

const controlStyle = {
    height: '2.8em',
    width: '2.8em',
    fill: 'rgb(var(--main-color))',
};

const controlStyleLight = {
    fill: INVERSE_COLOR,
};


const defaultProps = {
    arrow: null,
    onPress: noop,
    label: '',
    className: null,
    disabled: false,
    light: false,
};

class Control extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onButtonPress = this.onButtonPress.bind(this);
    }

    onButtonPress() {
        const {onPress} = this.props;
        onPress();
        return false;
    }

    render() {
        const {
            arrow,
            label,
            className,
            disabled,
            light,
        } = this.props;

        return (
            <button
                type="button"
                className={classnames('gallery-control', className)}
                onClick={this.onButtonPress}
                disabled={disabled}
                aria-label={label}
            >
                <svg
                    viewBox="0 0 18 18"
                    role="presentation"
                    focusable="false"
                    aria-hidden="true"
                    style={{
                        ...controlStyle,
                        ...(light && controlStyleLight),
                    }}
                >
                    <path
                        d={arrow}
                        fillRule="evenodd"
                    />
                </svg>
            </button>
        );
    }
}

Control.defaultProps = defaultProps;

export default Control;
